<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">项目信息</span>
        </el-col>
      </el-row>
      <div ref="searchBar" class="form-cls">
        <el-form @heightChange="_getTableHeight()" size="small">
          <el-row>
            <el-col :span="6">
              <el-form-item label="项目名称:">
                <el-input
                  v-model.trim="queryParams.name"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="施工单位:">
                <el-input
                  v-model.trim="queryParams.constructProjedt"
                  clearable
                  type="text"
                  class="input-cls input-width-6"
                  placeholder="请输入内容"
                  @keyup.enter.native="queryList"
                />
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item>
                <el-button plain size="small" type="primary" icon="el-icon-search" @click="queryList"> 查询 </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <!-- </search-bar> -->
      </div>
      <div class="main-body">
        <vxe-toolbar ref="xToolbar" custom style="margin-left: 10px; padding-right: 10px">
          <template #buttons>
            <el-upload
              :action="`${uploadUrl}?type=0`"
              :data="uploadData"
              :headers="uploadHeaders"
              :on-success="uploadFileSuccess"
              :on-error="uploadFileError"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addFile" size="small" type="primary"> 新增导入 </el-button>
            </el-upload>

            <el-upload
              style="margin-left: 10px"
              :data="uploadData"
              :headers="uploadHeaders"
              :on-success="uploadFileSuccess"
              :on-error="uploadFileError"
              :action="`${uploadUrl}?type=1`"
              :show-file-list="false"
              :file-list="[]"
            >
              <el-button plain key="addUpdate" size="small" type="primary"> 导入更新 </el-button>
            </el-upload>
            <el-button plain style="margin-left: 10px" key="addFile1" size="small" @click="exportFileTemp" type="primary">下载导入模板</el-button>
            <el-button plain   style="margin-left: 10px" key="addFile" size="small" @click="exportFile" type="primary"> 导出 </el-button>
          </template>
          <template #tools>
            <vxe-pager
              align="left"
              placement="top"
              :total="total"
              :current-page.sync="queryParams.currentPage"
              border
              :page-sizes="[100, 120, 150]"
              size="small"
              :page-size.sync="queryParams.pageSize"
              :layouts="['PrevJump', 'PrevPage', 'Jump', 'NextPage', 'NextJump', 'Sizes', 'Total']"
              @page-change="queryList('0')"
            />
          </template>
        </vxe-toolbar>
      </div>
      <el-row class="form-cls button-cls">
        <el-col :span="24">
          <vxe-table
            id="dataListTable"
            ref="table"
            v-loading="listLoading"
            class="-column-demo"
            :export-config="{}"
            :data="dataList"
            fit
            :height="_tableHeight"
            element-loading-text="加载中"
            border
            stripe
            column-key
            resizable
            highlight-current-row
            :resizable-config="{ minWidth: 50 }"
            :custom-config="{ storage: true }"
            :scroll-y="{ gt: -1 }"
            show-overflow="tooltip"
            show-footer-overflow="tooltip"
            show-header-overflow="tooltip"
            tooltip-effect="dark"
            size="small"
            style="width: 100%"
          >
            <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
            <vxe-table-column field="permitNumber" title="施工许可证" align="center" width="200" />
            <vxe-table-column field="name" title="项目名称" align="center" width="300" />
            <vxe-table-column field="abbreviation" title="项目简称" align="center" width="250" />
            <vxe-table-column field="longitude" title="经度" align="center" width="200" />
            <vxe-table-column field="latitude" title="纬度" align="center" width="200" />
            <vxe-table-column field="address" title="项目位置" align="center" width="300" />
            <vxe-table-column field="localStreet" title="属地街道" align="center" width="120" />
            <vxe-table-column field="scale" title="项目规模（平方米）" align="center" width="200" />
            <vxe-table-column field="totalEarthworkQuantity" title="总土方量（万方）" align="center" width="120" />
            <vxe-table-column field="remainEarthworkQuantity" title="剩余土方量（万方）" align="center" width="120" />
            <vxe-table-column field="__type" title="项目类别" align="center" width="120" />
            <vxe-table-column field="isKeyProject" title="是否重点工程" align="center" width="120" />
            <vxe-table-column field="buildProjedt" title="建设单位" align="center" width="200" />
            <vxe-table-column field="buildProjectContact" title="建设单位联系人" align="center" width="200" />
            <vxe-table-column field="buildProjectPhone" title="建设单位联系电话" align="center" width="200" />
            <vxe-table-column field="constructProjedt" title="施工单位" align="center" width="200" />
            <vxe-table-column field="constructProjectContact" title="施工单位联系人" align="center" width="120" />
            <vxe-table-column field="constructProjectPhone" title="施工单位联系电话" align="center" width="120" />
            <vxe-table-column field="openTime" title="开工日期" align="center" width="120" />
            <vxe-table-column field="endTime" title="计划竣工日期" align="center" width="120" />
            <vxe-table-column field="depth" title="基坑深度" align="center" width="120" />
            <vxe-table-column field="totalOrders" title="历史总工单" align="center" width="120" />
            <vxe-table-column field="__status" title="工地状态" align="center" width="120" />
            <vxe-table-column align="center" fixed="right" title="操作" width="160">
              <template v-slot="{ row }">
                <span style="color: deepskyblue; cursor: pointer" @click="handleWeilan(row)">围栏标注</span>
                <span style="color: deepskyblue; cursor: pointer; margin-left: 10px" @click="handleContacts(row)">联系人管理</span>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-col>
      </el-row>

      <!-- 围栏 -->
      <el-dialog :visible.sync="weilanVisible" :before-close="weilanClose" title="围栏标注" width="1290px" :close-on-click-modal="false">
        <el-row :gutter="24">
          <el-col :span="16">
            <div class="amap-section">
              <el-amap
                :plugin="plugin"
                :center="center"
                :amap-manager="amapManager"
                :zoom="zoom"
                :events="events"
                :resize-enable="true"
                class="map"
              ></el-amap>
            </div>
          </el-col>
          <el-col :span="8">
            <el-form :model="formData" :rules="formRules" ref="formData" label-width="92px">
              <el-form-item label="围栏名称：" prop="name">
                <el-input v-model="formData.name" size="small" class="input-width-16" />
              </el-form-item>
              <el-form-item label="触发类型：" prop="driveType">
                <el-checkbox-group v-model="formData.driveType">
                  <el-checkbox :label="1" name="driveType">驶入围栏</el-checkbox>
                  <el-checkbox :label="2" name="driveType">驶出围栏</el-checkbox>
                </el-checkbox-group>
              </el-form-item>
              <el-form-item label="中心经度："  prop="longitude">
                <el-input v-model="formData.longitude" size="small" class="input-width-16" @change="weilanDataUpdate" />
              </el-form-item>
              <el-form-item label="中心纬度：" prop="latitude">
                <el-input v-model="formData.latitude" size="small" class="input-width-16" @change="weilanDataUpdate" />
              </el-form-item>
              <el-form-item label="围栏半径：" prop="radius">
                <el-input v-model="formData.radius" size="small" class="input-width-16" @change="weilanDataUpdate">
                  <template slot="append">米</template>
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="submitData">保存</el-button>
                <el-button @click="weilanVisible = false">取 消</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-dialog>

      <!-- 联系人 -->
      <el-dialog title="联系人管理" width="1000px" :visible.sync="contactsVisible">
        <vxe-table
          :data="contactsDataList"
          ref="contactsTable"
          fit
          border
          stripe
          column-key
          resizable
          highlight-current-row
          show-overflow="tooltip"
          tooltip-effect="dark"
          :edit-config="{trigger: 'manual', mode: 'row', showStatus: true}"
          size="small"
        >
          <vxe-table-column type="seq" title="序号" width="50" align="center" fixed="left" />
          <vxe-table-column field="name" title="姓名" :edit-render="{name: 'input'}" align="center" width="120" />
          <vxe-table-column field="duty" title="职能" :edit-render="{name: 'input'}" align="center" />
          <vxe-table-column field="phone" title="手机号" :edit-render="{name: 'input'}" align="center" />
          <vxe-table-column field="notificationType" title="通知类型" :edit-render="{name: 'select'}" width="320" align="center" >
            <template #edit="{ row }">
              <vxe-select v-model="row.notificationType" transfer multiple>
                <vxe-option  v-for="item in  noticeOptions" :value="item.value" :label="item.label" :key="item.value"> {{item.label}} </vxe-option>
              </vxe-select>
            </template>
          </vxe-table-column>
          <vxe-table-column align="center" title="操作" width="160">
            <template #header>
              <div @click="handleContactsAdd" class="handleContactsAdd">操作 <i class="vxe-icon-square-plus"></i></div>
            </template>
            <template v-slot="{ row }">
              <!-- <span style="color: deepskyblue; cursor: pointer" @click="handleContactsCancel(row)">取消</span> -->
              <span style="color: deepskyblue; cursor: pointer" @click="handleContactsEdit(row)">编辑</span>
              <span style="color: deepskyblue; cursor: pointer; margin-left: 10px" @click="handleContactsDel(row)">删除</span>
            </template>
          </vxe-table-column>
        </vxe-table>
        <template slot="footer">
          <el-button type="primary" @click="submitContacts">保存</el-button>
          <el-button @click="contactsVisible = false">取 消</el-button>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { AMapManager } from 'vue-amap'
import CalTableHeight from '@/mixins/calTableHeight'
import { baseHomeList as queryList, baseHomeListExport as queryListExport,
  baseHomeDownloadTemplate as downloadTemplate,
  getElectricFence, getElectricFenceEdit, contactList, contactListSave, contactListAdd, contactListDel, contactListEdit } from '@/api/base'
import { getToken } from '@/utils/auth.js'
import downloadFile  from '@/utils/downloadFile'
const amapManager = new AMapManager()
const contactsRow = {
  id: "",
  duty: "", // 职能
  name: "", // name
  notificationType: "", // 通知类型
  phone: "", // 手机号
}
let circleMap = null
let markerMap = null
export default {
  data() {
    return {
      total: 0,
      uploadUrl: '/admin-gov/project/upload', // admin-gov
      uploadData: {},
      uploadHeaders:{
        Authorization: `Bearer ${getToken()}` // 让每个请求携带自定义token 请根据实际情况自行修改
      },
      queryParams: {
        // name: '国科大杭高院双浦校区项目（一期）',
        name: '',
        constructProjedt: '',
        currentPage: 1,
        pageSize: 100
      },
      dataList: [],
      contactsDataList: [],
      contactsSelectRow: {},
      noticeOptions: [
        { label: '离线', value: '离线' },
        { label: '超时', value: '超时' },
        { label: '非审批', value: '非审批' },
        { label: '超速', value: '超速' }
      ],
      contactsVisible: false,
      listLoading: false,
      weilanVisible: false,
      // 表单
      formData: {
        dataId: '',
        name: '',
        driveType: [],
        radius: 100,
        latitude: '120.236713',
        longitude: '30.206855'
      },
      formRules: {
        name: [{ required: true, message: '请输入围栏名称', trigger: 'blur' }],
        type: [{ required: true, message: '请选择触发类型', trigger: 'blur' }],
        radius: [{ required: true, message: '请输入围栏半径', trigger: 'blur' }]
      },
      // 地图
      map: null,
      plugin: [
        'AMap.OverView', // 地图鹰眼插件
        'AMap.ToolBar', //  地图工具条
        'AMap.MapType' //  类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
      ],
      resizeEnable: false, //是否监控地图容器尺寸变化
      zoom: 16, // 设置初始化级别
      mapStyle: 'amap://styles/blue', // 使用的自
      center: [120.236713, 30.206855],
      amapManager,
      events: {
        init: map => {
          this.map = map
          console.log('map init ok', map)
        }
      }
    }
  },
  mixins: [CalTableHeight],
  async mounted() {
    this.$nextTick(() => {
      // 手动将表格和工具栏进行关联,否则列配置将失效
      this.$refs.table.connect(this.$refs.xToolbar)
    })
    this.$nextTick(() => {
      this._getTableHeight()
    })
    this.queryList()
  },
  methods: {
    uploadFileSuccess() {
      this.$message.success(`上传成功`)
    },
    uploadFileError(err) {
      const res = JSON.parse(err.message)
      this.$message.error( res.message || `上传失败`)
    },
    exportFile() {
      const { queryParams } = this
      queryListExport({
        ...queryParams
      }).then(res => {
        downloadFile(res)
      })
    },
    exportFileTemp(){
      return downloadTemplate({
      }).then(res=>{
        downloadFile(res)
      })
    },
    queryList() {
      this.listLoading = true
      return queryList(this.queryParams)
        .then(({ data }) => {
          this.dataList = data.records.map(ele=>{
            return {
              ...ele,
              __type: ele.projectCategories,// ['工地'][ele.type] || ele.type,
              __status: ['已开工', '未开工'][ele.status] || ele.status
            }
          })
          this.total = data.total
        })
        .finally(() => {
          this.listLoading = false
        })
    },
    handleWeilan(row) {
      getElectricFence({type: 0, dataId: row.id}).then(({data}) => {
        const _data = data[0] || {}
        if(!_data.latitude && !_data.longitude) {
          this.$message.error('未获取到经纬度数据')
        }
        this.formData = {
          ...this.formData,
          ..._data,
          name:_data.name,
          dataId: row.id,
          radius: _data.r,
          latitude: _data.latitude || this.center[0],
          longitude: _data.longitude || this.center[1],
          driveType: [],
          // driveIn: data.driveIn, // 驶入告警 0-不告警 1-告警
          // driveOut: data.driveOut // 驶出告警 0-不告警 1-告警
        }
        if(_data.driveIn === 1) { 
          this.formData.driveType.push(1) // 驶入告警
        }
        if(_data.driveOut === 1) {
          this.formData.driveType.push(2) // 驶出告警
        }
        this.weilanVisible = true
        setTimeout(() => {
          this.startTrack()
        })
      })
    },
    handleContacts(row){
      contactList({dataId : row.id }).then(({data})=>{
        this.contactsVisible = true
        this.contactsSelectRow = row
        this.contactsDataList = (data || []).map((ele, index)=>{
          return {
            ...contactsRow,
            ...ele,
            notificationType: ele.notificationType.split('|'),
            id: ele.id,
            __id: index,
          }
        })
      })
    },
    handleContactsEdit(row){
      const $table = this.$refs.contactsTable
      if ($table) {
        return $table.setEditRow(row)
      }
    },
    async handleContactsCancel() {
      const $table = this.$refs.contactsTable
      if ($table) {
        await $table.clearEdit()
      }
    // 还原数据
    // await $table.revertData(row)
    },
    isActiveStatus (row){
      const $table = this.$refs.contactsTable
      if ($table) {
        return $table.isEditByRow(row)
      }
    },
    handleContactsAdd(){
      this.contactsDataList = [...this.contactsDataList, {
        ...contactsRow,
        __type: 'newAdd'
      } ]
    },
    handleContactsDel(row){
      contactListDel({id: row.id}).then((data)=>{
        console.log(data)
        this.contactsDataList = this.contactsDataList.filter((ele)=>{
          return ele.__id !==  row.__id
        })
      })
    },
    contactListSave(){
      const { id } = this.contactsSelectRow
      const list = this.contactsDataList.map((ele)=>{
        return {
          dataId: ele.dataId || id,
          duty: ele.duty,
          name: ele.name,
          notificationType: Array.isArray(ele.notificationType) ?  ele.notificationType.join('|') : ele.notificationType,
          phone: ele.phone
        }
      })
      console.log(list, 'list')
      contactListSave({
        dataId: id,
        list
      }).then(()=>{
        this.$message.success(`保存成功`)
      })
    },
    resetForm(){},
    submitData() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在保存...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.6)',
            target: document.getElementById('el-dialog')
          })
          const { driveType = [] } = this.formData
          const driveIn = driveType.includes(1) ? 1 :0
          const driveOut = driveType.includes(2) ? 1 :0
          const { radius, latitude, longitude} = this.formData
          getElectricFenceEdit({
            ...this.formData,
            driveIn,
            driveOut,
            r: parseFloat(radius),
            driveType: undefined,
            radius: undefined,
          }).then(res => {
            if (res.code === 200) {
              this.weilanVisible = false
              this.$message.success('保存成功')
              this.resetForm()
              this.queryList()
            } else {
              this.$message.error(res.msg)
            }
          }).finally(()=>{
            loading.close()
          })
        }
      })
      // setTimeout(() => {
      //   this.startTrack()
      // })
    },
    weilanDataUpdate(){
        // 更新地图
        // const latitude = '120.236713' 30.126564 120.122996
        // const longitude = '30.206855'
        const { radius, latitude, longitude} = this.formData
        circleMap.setRadius(radius)
        circleMap.setCenter(
          new AMap.LngLat( longitude, latitude ),
        )
        markerMap.setPosition( 
          new AMap.LngLat( longitude, latitude ),
        )

        this.map.setCenter( 
          new AMap.LngLat( longitude, latitude ),
        )
    },
    submitContacts(){
      console.log('contactsDataList', this.contactsDataList)
      this.contactListSave()
    },
    weilanClose(done ){
      console.log('weilanClose')
      this.map.remove(markerMap)
      this.map.remove(circleMap)
      done()
    },
    startTrack() {
      const map = amapManager.getMap()
      if(!this.map){
        this.map = map
      }
      if(markerMap) {
        map.remove(circleMap)
      }
      if(circleMap) {
        map.remove(circleMap)
      }

      const { latitude: Lat, longitude: Lng, radius = 0 } = this.formData

      console.log(`latitude:`, Lat, `longitude:`, Lng)

      const marker = new AMap.Marker({
        map: map,
        icon: new AMap.Icon({
          size: new AMap.Size(25, 34),
          image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png',
          imageSize: new AMap.Size(135, 40),
          imageOffset: new AMap.Pixel(-95, -3)
        }),
        position:new AMap.LngLat(Lng, Lat),
        // position: ['120.236713', '31.206855'],
        offset: new AMap.Pixel(-13, -30)
      })
      const circle = new AMap.Circle({
        center: new AMap.LngLat(Lng, Lat),
        radius, //半径
        borderWeight: 0,
        fillOpacity: 0.4,
        fillColor: '#1791fc',
        strokeWeight: 0
      })

      map.setCenter( 
        new AMap.LngLat( Lng, Lat ),
      )
      circleMap = circle
      markerMap = marker
      map.add([circle, marker])
    }
  }
}
</script>

<style>
.vxe-select--panel.is--transfer{
	z-index: 9001!important;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}

.icon-cls-green {
  color: #67c23a;
}

.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss">
.amap-section {
  height: 600px;
}

.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}

.el-pagination {
  float: right;
}

.-column-demo .vxe-header--row .vxe-header--column.-ghost,
.-column-demo .vxe-header--row .vxe-header--column.-chosen {
  background-color: #dfecfb;
}

.vxe-table--footer .col-blue {
  background: #f2f5f0;
}

/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}

.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}

.write-color {
  border-color: #f0f0f0;
}

/*单号复制样式 结束*/
.input-width-6 {
  width: 60% !important;
}

.input-width-7 {
  width: 70% !important;
}

.input-width-8 {
  width: 80% !important;
}

.input-width-16 {
  width: 300px;
}

</style>
